<template>
  <div :class="alertType" role="alert">
    <div class="flex">
      <div class="py-1">
        <fluent-icon
          :class="iconClass"
          :icon="iconType"
          stroke-width="4"
          stroke="white"
        />
      </div>
      <div>
        <p v-if="title" class="font-bold text-2xl">{{ title }}</p>
        <div class="text-xl">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {

    alertType: function() {

      const classes = "border-t-4 rounded-b px-4 py-3 shadow-md mb-5 ";
      const colors = {
        success: classes + "bg-teal-100 border-teal-500 text-teal-900",
        warning: classes + "bg-yellow-100 border-yellow-500 text-yellow-900",
        danger: classes + "bg-red-100 border-red-500 text-red-900",
      }

      return colors[this.type];

    },

    iconClass: function() {

      const classes = "icon p-2 fill-current text-white rounded-full mr-4 ";
      const colors = {
        success: classes + "bg-teal-500",
        warning: classes + "bg-yellow-500",
        danger: classes + "bg-red-500",
      }

      return colors[this.type];
      
    },

    iconType: function() {
      
      const icons = {
        success: "checkmark",
        warning: "warning",
        danger: "dismiss"
      }
      return icons[this.type];

    }

  },
  props: {
    type: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/tailwind';
</style>