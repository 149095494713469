<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box" >
      <woot-modal-header :header-title="$t('IMPORT_CONTACTS.TITLE')">
        <p v-if="upload_status=='upload'">
          {{ $t('IMPORT_CONTACTS.DESC') }}
          <a :href="csvUrl" download="import-contacts-sample">{{
            $t('IMPORT_CONTACTS.DOWNLOAD_LABEL')
          }}</a>
        </p>
      </woot-modal-header>
      <div class="row modal-content" v-show="upload_status=='upload'">
        <div class="medium-12 columns">
          <label>
            <span>{{ $t('IMPORT_CONTACTS.FORM.LABEL') }}</span>
            <input
              id="file"
              ref="file"
              type="file"
              accept="text/csv"
              @change="handleFileUpload"
            />
          </label>
        </div>
        <div class="modal-footer medium-12">
          <div class="flex justify-end">
            <button class="button clear" @click.prevent="onClose">
              {{ $t('IMPORT_CONTACTS.FORM.CANCEL') }}
            </button>
            <woot-button
              :disabled="uiFlags.isCreating || !file"
              :loading="uiFlags.isCreating"
              @click="uploadFile"
            >
              {{ $t('IMPORT_CONTACTS.FORM.SUBMIT') }}
            </woot-button>
          </div>
        </div>
      </div>
      <div class="row modal-content" v-show="upload_status=='uploading'">
        <div v-if="upload_status=='uploading'" class="flex justify-center">
          <span class="spinner" />
        </div>
      </div>
      <div class="row modal-content" v-show="upload_status=='error_not_imported'">
        <div class="medium-12 columns">
          <AlertComponent
            type="danger"
            :title="$t('IMPORT_CONTACTS.NOT_IMPORT.TITLE')"
          >
            <p>{{ $t('IMPORT_CONTACTS.NOT_IMPORT.BODY') }}</p>
            <a href="#" style="color: blue;">{{ $t('IMPORT_CONTACTS.DOWNLOAD_LABEL') }}</a>
          </AlertComponent>
          <p>{{ $t('IMPORT_CONTACTS.NOT_IMPORT.DOWNLOAD_LABEL') }}</p>
        </div>
        <div class="modal-footer medium-12">
          <div class="flex justify-end">
            <button class="button clear" @click.prevent="onClose">
              {{ $t('IMPORT_CONTACTS.ERRORS_MESSAGE.CANCEL') }}
            </button>
            <woot-button
              :disabled="uiFlags.isCreating || !file"
              :loading="uiFlags.isCreating"
              @click="downloadFileExample"
            >
              {{ $t('IMPORT_CONTACTS.ERRORS_MESSAGE.DOWNLOAD') }}
            </woot-button>
          </div>
        </div>
      </div>
      <div class="row modal-content" v-show="upload_status=='upload_with_errors'">
        <div class="medium-12 columns">
          <AlertComponent
            type="danger"
            :title="error + $t('IMPORT_CONTACTS.ERRORS_MESSAGE.OF') + total + $t('IMPORT_CONTACTS.ERRORS_MESSAGE.CONTACT_WITH_ERROR')"
          >
            <p>{{ $t('IMPORT_CONTACTS.ERRORS_MESSAGE.BODY') }}</p>
            <a href="#">{{ $t('IMPORT_CONTACTS.ERRORS_MESSAGE.FILE_NAME') }}</a>
          </AlertComponent>
          <p>{{ $t('IMPORT_CONTACTS.ERRORS_MESSAGE.DOWNLOAD_LABEL') }}</p>
        </div>
        <div class="modal-footer medium-12">
          <div class="flex justify-end">
            <button class="button clear" @click.prevent="onClose">
              {{ $t('IMPORT_CONTACTS.ERRORS_MESSAGE.CANCEL') }}
            </button>
            <woot-button
              :disabled="uiFlags.isCreating || !file"
              :loading="uiFlags.isCreating"
              @click="downloadFile"
            >
              {{ $t('IMPORT_CONTACTS.ERRORS_MESSAGE.DOWNLOAD') }}
            </woot-button>
          </div>
        </div>
      </div>
      <div class="row modal-content" v-show="upload_status=='upload_success'">
        <div class="medium-12 columns">
          <div class="success-container">
            <img class="success-image" src = "/assets/success_image.svg" alt="success"/>
          </div>
          <div class="row flex justify-center">
            <div style="width: 70%; margin: auto;">
              <p style="font-size: 24px; font-weight: bold; text-align: center;">{{ $t('IMPORT_CONTACTS.UPLOAD_SUCCESS.BODY_FIRST') + success + $t('IMPORT_CONTACTS.UPLOAD_SUCCESS.BODY_SECOND')}}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer medium-12">
          <div class="flex justify-center" style="width: 100%;">
            <woot-button
              :disabled="uiFlags.isCreating || !file"
              :loading="uiFlags.isCreating"
              @click="confirm"
              style="margin: auto; display: block;"
            >
              {{ $t('IMPORT_CONTACTS.UPLOAD_SUCCESS.ACCEPT') }}
            </woot-button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../../../components/Modal';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { CONTACTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import Spinner from 'shared/components/Spinner';
import AlertComponent from '../../../../components/Alert';

export default {
  components: {
    Modal,
    Spinner,
    AlertComponent,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      file: '',
      upload_status: 'upload',
      total:0,
      success:0,
      error:0,
      error_file: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
    csvUrl() {
      return '/downloads/import-contacts-sample.csv';
    },
  },
  mounted() {
    this.$track(CONTACTS_EVENTS.IMPORT_MODAL_OPEN);
  },
  watch: {
    show(val) {
      if (!val) {
        this.upload_status = 'upload';
      }
    },
  },
  methods: {
    async uploadFile() {
      try {
        if (!this.file) return;
        this.upload_status = 'uploading';
        let res = await this.$store.dispatch('contacts/import', this.file);
        let data = res.data.payload;
        console.log(data);
        this.total = data.total_records;
        this.success = data.processed_records??0;
        this.error = this.total - this.success;

        if (this.error == this.total) {
          this.upload_status = 'error_not_imported';
        } else if (this.error > 0) {
          this.upload_status = 'upload_with_errors';
          this.error_file = "/downloads/fail/"+data.id+"_contacts.csv";
        } else {
          this.upload_status = 'upload_success';
        }

        this.$track(CONTACTS_EVENTS.IMPORT_SUCCESS);
        this.$root.$emit('fetchContacts');
      } catch (error) {
        this.showAlert(
          error.message || this.$t('IMPORT_CONTACTS.ERROR_MESSAGE')
        );
        this.$track(CONTACTS_EVENTS.IMPORT_FAILURE);
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    downloadFile(){
      window.open(this.error_file, '_blank');
      this.upload_status = 'upload_success';
    },
    downloadFileExample(){
      window.open(this.csvUrl, '_blank');
      this.upload_status = 'upload';
      this.onClose();
    },
    confirm() {
      this.upload_status = 'upload';
      this.onClose();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/woot';
.spinner {
  margin-top: var(--space-normal);
  margin-bottom: var(--space-normal);
}

.success-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: var(--space-normal);
  margin-bottom: var(--space-normal);
}

.success-image {
  width: 80px;
  height: 80px;
  margin: auto;
  display: block;
}
</style>
