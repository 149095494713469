import { render, staticRenderFns } from "./AddCategory.vue?vue&type=template&id=9c8ef900&scoped=true&"
import script from "./AddCategory.vue?vue&type=script&lang=js&"
export * from "./AddCategory.vue?vue&type=script&lang=js&"
import style0 from "./AddCategory.vue?vue&type=style&index=0&id=9c8ef900&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c8ef900",
  null
  
)

export default component.exports