<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.FACEBOOKCOMMENTS.NAME')"
      :header-content="$t('INBOX_MGMT.ADD.FACEBOOKCOMMENTS.DESC')"
    />
    <form class="row" @submit.prevent="sendInboxData()">
      <div class="medium-7 columns">
          <div class="medium-12 columns">
            <div class="input-wrap" :class="{ error: $v.selectedPage.$error }">
              {{ $t('INBOX_MGMT.ADD.FACEBOOKCOMMENTS.PAGE.LABEL') }}
              <multiselect
                v-model.trim="selectedPage"
                :close-on-select="true"
                :allow-empty="true"
                :options="activePages"
                track-by="id"
                label="name"
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                :placeholder="$t('INBOX_MGMT.ADD.FB.PICK_A_VALUE')"
                selected-label
                @select="selectPage"
              />
              <span v-if="$v.selectedPage.$error" class="message">
                {{ $t('INBOX_MGMT.ADD.FB.CHOOSE_PLACEHOLDER') }}
              </span>
            </div>
          </div>
        </div>

        <div class="medium-8 columns">
          <label :class="{ error: $v.channelName.$error }">
            {{ $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.LABEL') }}
            <input
              v-model.trim="channelName"
              type="text"
              :placeholder="
                $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
              "
              @blur="$v.channelName.$touch"
            />
            <span v-if="$v.channelName.$error" class="message">{{
              $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.ERROR')
            }}</span>
          </label>
        </div>

        <div class="medium-12 columns">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.FACEBOOKCOMMENTS.APINAME')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required,numeric } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
const axios = require('axios');


const shouldBeWebhookUrl = (value = '') =>
  value ? value.startsWith('http') : true;

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      webhookUrl: `${process.env.FACEBOOKCOMMENTS_APP_WEB_API}facebook/send_comment`,
      facebook_inbox_id: '',
      facebook_page_id: '',
      selectedPage: null,
      activePages: [],
      pageName: '',
      dataPages: [],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      currentUser: 'getCurrentUser'
    }),
  },
  created() {
    this.getFacebookInboxes()
  },
  validations: {
    channelName: { required },
    webhookUrl: { shouldBeWebhookUrl },
    facebook_inbox_id: { required },
    facebook_page_id: { required },
    selectedPage: {
      isEmpty() {
        return this.selectedPage !== null && !!this.selectedPage.name;
      },
    }
  },
  methods: {
    async createChannel() {
      try {
        const apiChannel = await this.$store.dispatch('inboxes/createChannel', {
          name: this.channelName,
          channel: {
            type: 'api',
            facebook_inbox_id: this.facebook_inbox_id,
            facebook_page_id: this.facebook_page_id,
            sub_type: 'facebookcomments',
          },
        });

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: apiChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.API_CHANNEL.API.ERROR_MESSAGE'));
      }
    },
    async getFacebookInboxes(){
      let self = this
      const config = {
        headers:{
          api_access_token: self.currentUser.access_token
        }
      };
      axios.get(`/api/v1/accounts/${self.currentUser.account_id}/channels/facebook_comments_channel/get_facebook_channel`, config).then((response) => {
        let pages = response.data
        self.dataPages = pages
        pages.forEach(function(page){
          self.activePages.push({
            id: page.inbox.id,
            name: page.inbox.name,
            page_id: page.channel.page_id,
            user_access_token: page.channel.user_access_token,
            page_access_token: page.channel.page_access_token
          })
        })
      })
    },
    selectPage(page){
      let self = this
      self.facebook_inbox_id = page.id,
      self.facebook_page_id = page.page_id,
      self.channelName = `Comentarios ${page.name}`
    },

    sendInboxData(){
      let self = this
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      axios.post(`${process.env.FACEBOOKCOMMENTS_APP_WEB_API}facebook/register_facebook_data`, self.selectedPage).then((response) => {
        if(response.data.status == 'success'){

          self.createChannel()
        }
      })
    },
  },
};
</script>
