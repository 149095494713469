<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="'SMS México API'"
      :header-content="$t('INBOX_MGMT.ADD.MARCATEL.DESC')"
    />
    <form class="row" @submit.prevent="createChannel()">
      <div class="medium-8 columns">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
            "
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
            $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.ERROR')
          }}</span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.marcatelUser.$error }">
          {{ $t('INBOX_MGMT.ADD.MARCATEL.USER.LABEL') }}
          <input
            v-model.trim="marcatelUser"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.MARCATEL.USER.PLACEHOLDER')
            "
            @blur="$v.marcatelUser.$touch"
          />
          <span v-if="$v.marcatelUser.$error" class="message">{{
            $t('INBOX_MGMT.ADD.MARCATEL.USER.ERROR')
          }}</span>
        </label>
      </div>

       <div class="medium-8 columns">
        <label :class="{ error: $v.marcatelPassword.$error }">
          {{ $t('INBOX_MGMT.ADD.MARCATEL.PASSWORD.LABEL') }}
          <input
            v-model.trim="marcatelPassword"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.MARCATEL.PASSWORD.PLACEHOLDER')
            "
            @blur="$v.marcatelPassword.$touch"
          />
          <span v-if="$v.marcatelPassword.$error" class="message">{{
            $t('INBOX_MGMT.ADD.MARCATEL.PASSWORD.ERROR')
          }}</span>
        </label>
      </div>

      <div class="medium-12 columns">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.MARCATEL.APINAME')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required,numeric } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';

const shouldBeWebhookUrl = (value = '') =>
  value ? value.startsWith('http') : true;

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      webhookUrl: 'https://api.callpicker.com/marcatel_integrations/cp_chat_webhook',
      marcatelUser: '', 
      marcatelPassword: '', 
      chatwootToken: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    channelName: { required },
    webhookUrl: { shouldBeWebhookUrl },
    marcatelUser: { required },
    marcatelPassword: { required },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const apiChannel = await this.$store.dispatch('inboxes/createChannel', {
          name: this.channelName,
          channel: {
            type: 'api',
            sub_type: 'marcatel',
            webhook_url: this.webhookUrl,
            marcatel_user: this.marcatelUser,
            marcatel_password: this.marcatelPassword
          },
        });

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: apiChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.API_CHANNEL.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
