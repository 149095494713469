<template>
  <div class="flex flex-center 2xl:flex-start flex-col 2xl:flex-row p-8 border border-gray-300 bg-gray-200 rounded-md mb-5">
    <div style="min-width:150px" class="py-1 w-1/2 2xl:w-1/4 3xl:w-1/3 mb-4">
      <img @click="$emit('retryQr')" alt="Código QR" :src="qrLink" /> 
    </div>
    <div class="px-5 2xl:w-3/4 3xl:w-2/3">
      <p class="font-bold text-2xl mb-4">{{ $t('INBOX_MGMT.ADD.WHATAPPWEB.QR_STEPS.TITLE') }}</p>
      <div class="text-xl">
        <ol class="list-decimal list-inside">
          <li>
            {{ $t('INBOX_MGMT.ADD.WHATAPPWEB.QR_STEPS.ANDROID.FIRST_STEP_BEFORE_ICON') }}
            <fluent-icon
              class="bg-white border border-gray-400 text-gray-700 rounded-md inline"
              icon="more-vertical"
            />
            {{ $t('INBOX_MGMT.ADD.WHATAPPWEB.QR_STEPS.ANDROID.FIRST_STEP_AFTER_ICON') }}
          </li>
          <li>{{ $t('INBOX_MGMT.ADD.WHATAPPWEB.QR_STEPS.ANDROID.SECOND') }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    qrLink: {
      type: String,
      required: true
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/tailwind';
</style>