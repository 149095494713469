var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column content-box"},[_c('woot-button',{attrs:{"color-scheme":"success","class-names":"button--fixed-top","icon":"add-circle"},on:{"click":function($event){return _vm.openAddPopup()}}},[_vm._v("\n    "+_vm._s(_vm.$t('CANNED_MGMT.HEADER_BTN_TXT'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"small-8 columns with-right-space "},[(!_vm.uiFlags.fetchingList && !_vm.records.length)?_c('p',{staticClass:"no-items-error-message"},[_vm._v("\n        "+_vm._s(_vm.$t('CANNED_MGMT.LIST.404'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.uiFlags.fetchingList)?_c('woot-loading-state',{attrs:{"message":_vm.$t('CANNED_MGMT.LOADING')}}):_vm._e(),_vm._v(" "),(!_vm.uiFlags.fetchingList && _vm.records.length)?_c('table',{staticClass:"woot-table"},[_c('thead',_vm._l((_vm.$t('CANNED_MGMT.LIST.TABLE_HEADER')),function(thHeader){return _c('th',{key:thHeader},[_vm._v("\n            "+_vm._s(thHeader)+"\n          ")])}),0),_vm._v(" "),_c('tbody',_vm._l((_vm.records),function(cannedItem,index){return _c('tr',{key:cannedItem.short_code},[_c('td',{staticClass:"short-code"},[_vm._v("\n              "+_vm._s(cannedItem.short_code)+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"wrap-break-words"},[_vm._v("\n              "+_vm._s(cannedItem.content.replaceAll('\\', ''))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"button-wrapper"},[(
                  !(
                    JSON.parse(cannedItem.settings) &&
                    JSON.parse(cannedItem.settings).template &&
                    JSON.parse(cannedItem.settings).template.type == 'gupshup'
                  ) || _vm.isCallpickerUser
                )?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('CANNED_MGMT.EDIT.BUTTON_TEXT')),expression:"$t('CANNED_MGMT.EDIT.BUTTON_TEXT')",modifiers:{"top":true}}],attrs:{"variant":"smooth","size":"tiny","color-scheme":"secondary","icon":"edit"},on:{"click":function($event){return _vm.openEditPopup(cannedItem)}}}):_vm._e(),_vm._v(" "),(
                  !(
                    JSON.parse(cannedItem.settings) &&
                    JSON.parse(cannedItem.settings).template &&
                    JSON.parse(cannedItem.settings).template.type == 'gupshup'
                  ) || _vm.isCallpickerUser
                )?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('CANNED_MGMT.DELETE.BUTTON_TEXT')),expression:"$t('CANNED_MGMT.DELETE.BUTTON_TEXT')",modifiers:{"top":true}}],attrs:{"variant":"smooth","color-scheme":"alert","size":"tiny","icon":"dismiss-circle","class-names":"grey-btn","is-loading":_vm.loading[cannedItem.id]},on:{"click":function($event){return _vm.openDeletePopup(cannedItem, index)}}}):_vm._e()],1)])}),0)]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"small-4 columns"},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.$t('CANNED_MGMT.SIDEBAR_TXT')),expression:"$t('CANNED_MGMT.SIDEBAR_TXT')"}]})])]),_vm._v(" "),_c('woot-modal',{attrs:{"show":_vm.showAddPopup,"on-close":_vm.hideAddPopup},on:{"update:show":function($event){_vm.showAddPopup=$event}}},[_c('add-canned',{attrs:{"on-close":_vm.hideAddPopup}})],1),_vm._v(" "),_c('woot-modal',{attrs:{"show":_vm.showEditPopup,"on-close":_vm.hideEditPopup},on:{"update:show":function($event){_vm.showEditPopup=$event}}},[(_vm.showEditPopup)?_c('edit-canned',{attrs:{"id":_vm.selectedResponse.id,"edshort-code":_vm.selectedResponse.short_code,"edcontent":_vm.selectedResponse.content,"on-close":_vm.hideEditPopup}}):_vm._e()],1),_vm._v(" "),_c('woot-delete-modal',{attrs:{"show":_vm.showDeleteConfirmationPopup,"on-close":_vm.closeDeletePopup,"on-confirm":_vm.confirmDeletion,"title":_vm.$t('CANNED_MGMT.DELETE.CONFIRM.TITLE'),"message":_vm.$t('CANNED_MGMT.DELETE.CONFIRM.MESSAGE'),"message-value":_vm.deleteMessage,"confirm-text":_vm.deleteConfirmText,"reject-text":_vm.deleteRejectText},on:{"update:show":function($event){_vm.showDeleteConfirmationPopup=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }