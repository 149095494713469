<template>
  <button
    v-if="isLink"
    :key="action.uri"
    class="action-button button"
    :style="{ borderColor: widgetColor, color: widgetColor }"
    @click="onClickLink"
  >
    {{ action.text }}
  </button>
  <button
    v-else
    :key="action.payload"
    class="action-button button"
    :style="{ borderColor: widgetColor, color: widgetColor }"
    @click="onClick"
  >
    {{ action.text }}
  </button>
</template>
<script>
import { mapGetters } from 'vuex';
import { getContrastingTextColor } from '@chatwoot/utils';
export default {
  components: {},
  props: {
    action: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      widgetColor: 'appConfig/getWidgetColor',
    }),
    textColor() {
      return getContrastingTextColor(this.widgetColor);
    },
    isLink() {
      console.log(this.action.type);
      return this.action.type == 'link';
    },
  },
  methods: {
    onClick() {
      // Do postback here
    },
    onClickLink() {
      window.open(this.action.uri, '_blank');
    }
  },
};
</script>

<style scoped lang="scss">
@import '~widget/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.action-button {
  align-items: center;
  border-radius: $space-micro;
  display: flex;
  font-weight: $font-weight-medium;
  justify-content: center;
  margin-top: $space-smaller;
  max-height: 34px;
  padding: 0;
  width: 100%;
}
</style>