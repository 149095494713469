<template>
    <div class="conversation flex justify-content-start align-items-center bg-red--custom max-w-full border--custom"
        style="min-height: 89.59px;" v-if="isWhatsappWebDisconnected">
        <div class="flex justify-content-start align-items-start mt-3" style="width: 35px; height: 100%;">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9 0.666748C13.6025 0.666748 17.3333 4.39758 17.3333 9.00008C17.3333 13.6026 13.6025 17.3334 9 17.3334C4.39751 17.3334 0.666672 13.6026 0.666672 9.00008C0.666672 4.39758 4.39751 0.666748 9 0.666748ZM11.9417 6.05841L11.8717 5.99758C11.7655 5.91909 11.6372 5.87625 11.5051 5.8752C11.3731 5.87415 11.2441 5.91495 11.1367 5.99175L11.0583 6.05841L9 8.11591L6.94167 6.05758L6.87167 5.99758C6.76548 5.91909 6.63716 5.87625 6.50512 5.8752C6.37308 5.87415 6.24409 5.91495 6.13667 5.99175L6.05834 6.05841L5.99751 6.12841C5.91901 6.2346 5.87617 6.36292 5.87512 6.49497C5.87407 6.62701 5.91488 6.75599 5.99167 6.86341L6.05834 6.94175L8.11584 9.00008L6.0575 11.0584L5.99751 11.1284C5.91901 11.2346 5.87617 11.3629 5.87512 11.495C5.87407 11.627 5.91488 11.756 5.99167 11.8634L6.05834 11.9417L6.12834 12.0026C6.23453 12.0811 6.36285 12.1239 6.49489 12.125C6.62693 12.126 6.75592 12.0852 6.86334 12.0084L6.94167 11.9417L9 9.88425L11.0583 11.9426L11.1283 12.0026C11.2345 12.0811 11.3628 12.1239 11.4949 12.125C11.6269 12.126 11.7559 12.0852 11.8633 12.0084L11.9417 11.9417L12.0025 11.8717C12.081 11.7656 12.1238 11.6372 12.1249 11.5052C12.1259 11.3732 12.0851 11.2442 12.0083 11.1367L11.9417 11.0584L9.88417 9.00008L11.9425 6.94175L12.0025 6.87175C12.081 6.76556 12.1238 6.63724 12.1249 6.5052C12.1259 6.37315 12.0851 6.24417 12.0083 6.13675L11.9417 6.05841Z"
                    fill="#E54651" />
            </svg>
        </div>
        <div class="flex flex-column" v-if="isWhatsAppInbox">
            <span class="conversation--user font-bold text-red-300">
                {{ $t('CHAT_LIST.WHATSAPP_QR.CLOSE_SESSION.SESSION_CLOSED') }}
            </span>
            <p class="conversation--message-custom">
                {{ $t('CHAT_LIST.WHATSAPP_QR.CLOSE_SESSION.RECEIVE_MORE_MESSAGES') }}
                <a @click="toSettings">
                    {{ $t('CHAT_LIST.WHATSAPP_QR.CLOSE_SESSION.CLICK') }}
                </a>
            </p>
        </div>
        <div class="flex flex-column" v-if="isWhatsBailInbox">
            <span class="conversation--user font-bold text-red-300">
                {{ $t('CHAT_LIST.WHATSAPP_QR.CLOSE_SESSION.SESSION_CLOSED') }}
            </span>
            <p class="conversation--message-custom" v-if="isAdmin">
                {{ $t('CHAT_LIST.WHATSAPP_QR.CLOSE_SESSION.RECEIVE_MORE_MESSAGES') }}
                <a @click="toSettings">
                    {{ $t('CHAT_LIST.WHATSAPP_QR.CLOSE_SESSION.CLICK') }}
                </a>
            </p>
            <p class="conversation--message-custom" v-else>
                {{ $t('CHAT_LIST.WHATSAPP_QR.CLOSE_SESSION.RECEIVE_MORE_MESSAGES_AGENT') }}
            </p>
        </div>
        <div class="flex flex-column" v-if="isMercadoLibreInbox">
            <span class="conversation--user font-bold text-red-300">
                {{ $t('CHAT_LIST.MERCADOLIBRE.CLOSE_SESSION.SESSION_CLOSED') }}
            </span>
            <p class="conversation--message-custom">
                {{ $t('CHAT_LIST.MERCADOLIBRE.CLOSE_SESSION.RECEIVE_MORE_MESSAGES') }}
                <a @click="toSettings">
                    {{ $t('CHAT_LIST.MERCADOLIBRE.CLOSE_SESSION.CLICK') }}
                </a>
            </p>
        </div>
    </div>
</template>

<script>
import inboxMixin from 'shared/mixins/inboxMixin';
import adminMixin from 'dashboard/mixins/isAdmin';

export default {
    mixins: [inboxMixin, adminMixin],
    props: {
        inbox: {
            type: Object,
            default: {}
        }
    },
    computed: {
        isWhatsappWebDisconnected() {
            return this.inbox?.settings?.is_disconnected ?? false;
        }
    },
    methods: {
        toSettings() {
            this.$router.push({
                name: "settings_inbox_show",
                params: {
                    accountId: this.$route.params.accountId,
                    inboxId: this.inbox.id
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.text-white {
    color: white;
}

.font-bold {
    font-weight: bold;
}

.border-circle {
    border-radius: 50%;
}

.border--custom {
    border-bottom: 1px solid #F5BBC4 !important;
}

.conversation--message-custom {
    margin-top: 8px;
    color: #3C4858 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.conversation--message-custom>a {
    color: #3C4858 !important;
    text-decoration: underline;
}

.w-20 {
    width: 20%;
}

.w-80 {
    width: 80%;
}

.text-red-300 {
    color: #E54651;
}

.font-bold {
    font-weight: 500;
}

.max-w-full {
    max-width: 100%;
}

.bg-red--custom {
    background: #FFE2E7;
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-start {
    justify-content: flex-start;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: start;
}

.mt-3 {
    margin-top: 3rem;
}</style>
