export const findPendingMessageIndex = (chat, message) => {
  const { echo_id: tempMessageId } = message;
  return chat.messages.findIndex(
    m => m.id === message.id || m.id === tempMessageId
  );
};

export const filterByStatus = (chatStatus, filterStatus) =>
  filterStatus === 'all' ? true : chatStatus === filterStatus;

export const filterByInbox = (shouldFilter, inboxId, chatInboxId) => {
  const isOnInbox = Number(inboxId) === chatInboxId;
  return inboxId ? isOnInbox && shouldFilter : shouldFilter;
};

export const filterByTeam = (shouldFilter, teamId, chatTeamId) => {
  const isOnTeam = Number(teamId) === chatTeamId;
  return teamId ? isOnTeam && shouldFilter : shouldFilter;
};

export const filterByLabel = (shouldFilter, labels, chatLabels) => {
  const isOnLabel = labels.every(label => chatLabels.includes(label));
  return labels.length ? isOnLabel && shouldFilter : shouldFilter;
};
    export const filterByUnattended = (
      shouldFilter,
      conversationType,
      firstReplyOn,
      chatStatus
    ) => {
      return conversationType === 'unattended'
      
        ? !firstReplyOn && shouldFilter || chatStatus !== 'resolved'  && shouldFilter
        : shouldFilter;
    };
    

    export const applyPageFilters = (conversation, filters) => {
      const { inboxId, status, labels = [], teamId, conversationType } = filters;
      
      const {
        status: chatStatus,
        inbox_id: chatInboxId,
        labels: chatLabels = [],
        meta = {},
        first_reply_created_at: firstReplyOn,
        messages = [],
      } = conversation;
      
      const team = meta.team || {};
      const { id: chatTeamId } = team;
    
      let shouldFilter = filterByStatus(chatStatus, status);
      shouldFilter = filterByInbox(shouldFilter, inboxId, chatInboxId);
      shouldFilter = filterByTeam(shouldFilter, teamId, chatTeamId);
      shouldFilter = filterByLabel(shouldFilter, labels, chatLabels);

      let lastMessage = null;

      if (messages.length > 0) {
          let i = messages.length - 1;
          while (i >= 0 && !messages[i].content) {
              i--;
          }
          if (i >= 0) {
              lastMessage = messages[i];
          }
      } else {
          console.log("El array de mensajes está vacío.");
      }

      shouldFilter = filterByUnattended(
        shouldFilter,
        conversationType,
        firstReplyOn,
        lastMessage
      );
      return shouldFilter;
    };