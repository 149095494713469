<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.MERCADOLIBRE.NAME')"
      :header-content="$t('INBOX_MGMT.ADD.MERCADOLIBRE.DESC')"
    />
    <form class="row" @submit.prevent="ML_Loggin()">
      <div class="medium-8 columns">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.MERCADOLIBRE.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.MERCADOLIBRE.CHANNEL_NAME.PLACEHOLDER')
            "
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
            $t('INBOX_MGMT.ADD.MERCADOLIBRE.CHANNEL_NAME.ERROR')
          }}</span>
        </label>
      </div>
      
      <div class="medium-8 columns">
        <label :class="{ error: $v.channelEmail.$error }">
          {{ $t('INBOX_MGMT.ADD.MERCADOLIBRE.CHANNEL_EMAIL.LABEL') }}
          <input
            v-model.trim="channelEmail"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.MERCADOLIBRE.CHANNEL_EMAIL.PLACEHOLDER')
            "
            @blur="$v.channelEmail.$touch"
          />
          <span v-if="$v.channelEmail.$error" class="message">{{
            $t('INBOX_MGMT.ADD.MERCADOLIBRE.CHANNEL_EMAIL.ERROR')
          }}</span>
          <span v-else class="help-text message">
            {{ $t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.FAILED.BODY') }}  
            <b>{{ $t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.FAILED.STEPS') }}</b>
          </span>
        </label>
      </div>

      <AlertComponent
            v-if="timeout && !success_login"
            type="danger"
            :title="$t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.FAILED.TITLE')"
          >
            <p class="mb-4">
              {{ $t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.FAILED.BODY') }} <br> 
              <b>{{ $t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.FAILED.STEPS') }}</b> 
            </p>
      </AlertComponent>

      <div class="medium-12 columns">
        <woot-submit-button
          :loading="validating"
          :button-text="$t('INBOX_MGMT.ADD.MERCADOLIBRE.APINAME')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required,numeric } from 'vuelidate/lib/validators';
import AlertComponent from '../../../../../components/Alert';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import SocketIO from 'socket.io-client'

const shouldBeWebhookUrl = (value = '') =>
  value ? value.startsWith('http') : true;

export default {
  components: {
    PageHeader,
    AlertComponent
  },
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      webhookUrl: `${process.env.MERCADOLIBRE_APP_WEB_API}api/mercado_libre/chat_callpicker_webhook`,
      channelEmail: '',
      socket: null,
      ioChanelListen:false,
      timer: null,
      timeout: false,
      success_login: false,
      validating: false
    };
  },
  beforeDestroy() {
    this.closeSocket()
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    channelName: { required },
    channelEmail: { required },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const apiChannel = await this.$store.dispatch('inboxes/createChannel', {
          name: this.channelName,
          channel: {
            type: 'api',
            sub_type: 'mercadolibre',
            webhook_url: this.webhookUrl,
            email: this.channelEmail,
            ventana:null
          },
        });

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: apiChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.API_CHANNEL.API.ERROR_MESSAGE'));
      }
    },
    async ML_Loggin(){
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      self = this
      self.validating = true;
      if(self.timer != null){
        clearTimeout(self.timer)
      }
      self.timeout = false
      self.success_login = false
      var socket_url = process.env.MERCADOLIBRE_APP_WEB_WSS;
      /*var socket_url = process.env.ML_WEB_WSS;
        if(JSON.parse(self.otherClients).includes(self.accountId+"")){
          socket_url = process.env.WHATS_APP_WEB_WSS_OTHER;
        }*/

      if(self.ioChanelListen == false){
        self.ioChanelListen = true;
        self.socket = await SocketIO(socket_url, {
          transports: ['websocket']
        });
      }
      var left = (screen.width - 1200) / 2;
            var top = (screen.height - 650) / 4;
             
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1200,height=650,left=${left},top=${top}`;

      self.ventana = open(`https://auth.mercadolibre.com.mx/authorization?response_type=code&client_id=${process.env.MERCADOLIBRE_CLIENT_ID}`, 'test', params);

      self.timer = setTimeout(function(){
        self.validating = false;
        self.ioChanelListen = false;
        self.timeout = true;
        self.socket.close()
      }, 60000)

      await self.socket.on(`ml_channel:${self.channelEmail}`, (data) => {
        self.socket.close()
        if(data == "create_success"){
          self.success_login = true
          self.timeout = false
          self.ventana.close()
          self.createChannel()
        }
      })
    },
    closeSocket(){
      if(self.ioChanelListen == true){
        this.socket.emit("client_out",true)
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';
.help-text {
    font-style: normal;
    color: var(--b-500);
    padding-bottom: var(--space-smaller);
}
</style>