import Facebook from './channels/Facebook';
import Website from './channels/Website';
import Twitter from './channels/Twitter';
import Api from './channels/Api';
import Email from './channels/Email';
import Gupshup from './channels/Gupshup';
import Marcatel from './channels/Marcatel';
import Whatsappweb from './channels/Whatsappweb';
import Sms from './channels/Sms';
import Whatsapp from './channels/Whatsapp';
import Line from './channels/Line';
import Telegram from './channels/Telegram';
import MercadoLibre from './channels/MercadoLibre';
import FacebookComments from './channels/FacebookComments';

const channelViewList = {
  facebook: Facebook,
  website: Website,
  twitter: Twitter,
  api: Api,
  email: Email,
  sms: Sms,
  whatsapp: Whatsapp,
  line: Line,
  telegram: Telegram,
  gupshup: Gupshup,
  marcatel: Marcatel,
  whatsappweb: Whatsappweb,
  mercadolibre: MercadoLibre,
  facebookcomments: FacebookComments,
};

export default {
    create() {
        return {
            props: {
                channel_name: {
                    type: String,
                    required: true,
                },
            },
            name: 'new-channel-view',
            render(h) { 
                console.log(this.channel_name);
                return h(channelViewList[this.channel_name] || null);
            },
        };
    },
};
